import buttons from './buttons'

export default {
  // ? ==========================
  // ? ====  Locaition Page  ====
  // ? ==========================

  locationsPage: {
    '.logo': {
      maxWidth: ['175px', '', '200px', '300px'],
    },
    // '.CTAS .CTAButton': {
    //   border: 'solid 2px white',
    //   fontFamily: 'Gochi Hand',
    // },
  },

  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    // ? === nav container ===
    '.container': {
      padding: '1rem',
    },

    // ? === nav container scrolled ===
    '.containerScrolled': {
      backgroundColor: 'secondary',
      borderBottom: 'solid 2px',
      borderColor: '#ffb1b1',
      // boxShadow: '2px 2px white',
    },

    // ? === nav logo ===
    '.logo': {
      img: {
        maxHeight: ['110px', '', '200px'],
      },
    },

    '.logoScrolled': {
      img: {},
    },

    // ? === hamburger ===
    '.hamburger div, .hamburgerOpen div': {
      backgroundColor: 'light',
      borderRadius: '0px',
      height: '2px',
    },

    // ? === Desktop nav menu ===
    '.smallNavMenu': {
      '.navItem': {
        a: {
          color: 'light',
          fontSize: '1.25rem',
          color: 'white',
          ':hover': {
            color: 'grey',
          },
        },
      },
      '> .online-order': {
        a: {
          margin: '0 1rem',
          variant: 'buttons.secondary',
          fontSize: ['1rem', '1rem', '1rem'],
        },
      },
    },

    // ? === Drawer nav menu ===
    '.navMenuOpen': {
      padding: ['2.5rem', '', '', '2rem'],
      backgroundColor: 'secondary',
      minWidth: '275px',
      '.navItem': {
        color: 'light',
        paddingBottom: '0.5rem',
        borderBottom: '1px solid #757575',
        width: '100%',
      },

      '.navItemDropdown': {
        width: '100%',
      },
      '.navItemDropdownMenu': {
        backgroundColor: 'transparent',
      },
    },

    // ? === Drawer nav menu ===

    '.navItem': {
      'a:hover': {
        color: 'primary',
      },
    },

    // ? === Drawer nav bg block ===

    '.navBlock': {
      backgroundColor: 'rgba(0,0,0,0.8)',
    },

    // ? === Widgets ===

    '.socialContainer': {
      svg: {
        width: '25px',
        height: '25px',
        path: {
          fill: 'light',
        },
      },
    },
  },

  footer: {
    borderTop: 'solid 3px',
    borderColor: 'primary',
    backgroundColor: 'secondary',
    color: 'white',
    '.contactColumn': {
      border: 'none !important',
    },
    '.aboutText': {
      marginBottom: '1.5rem',
    },

    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start'],
      flexDirection: ['', '', '', 'column'],
      a: {
        padding: ['', '', '', '0.75rem 0.5rem 0.75rem 0rem'],
      },
    },

    '.contactDetails-container': {
      marginBottom: '1rem',
    },
    '.sectionHeading': {
      fontWeight: 'normal',
      fontSize: '2.5rem',
      color: 'white',
    },
    '.locationSwitcherV1': {
      marginTop: '1rem',
      '.button': {
        variant: 'buttons.primary',
        flexDirection: 'row',
        svg: {
          marginRight: '0.5rem',
        },
      },
    },

    '.footerAboutButton': {
      '.ctaButton': {
        variant: 'buttons.secondary',
        backgroundColor: 'white',
        borderRadius: '0px',
      },
    },
    '.poweredByText': {
      filter: 'brightness(0) invert(1)',
      margin: '0rem auto 1rem 0rem',
      justifyContent: 'flex-start',
    },
  },

  ctaWidget: {
    zIndex: '999',
    color: 'light',
  },

  // ? ==========================
  // ? ======  reuseables  ========
  // ? ==========================

  title: {
    borderBottom: '3px solid',
    borderColor: 'grey',
    paddingBottom: '1rem',
    fontSize: ['2rem', '', '3rem'],
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'primary',
  },

  subtitle: {
    fontWeight: 'normal',
    fontSize: ['1.5rem', '', '2rem'],
  },

  text: {
    color: 'textSecondary',
    fontSize: ['1rem', '', '', '1.1rem'],
    lineHeight: '1.5',
    marginBottom: '2rem',
  },

  pageHero: {
    alignItems: 'flex-end',
    // borderRadius: '0px 0px 100px 0px',
    // clipPath: 'polygon(100% 0, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0 0)',
    height: ['60vh', '50vh', '', '75vh'],
    '.content': {
      h1: { marginBottom: '4rem' },
    },
  },

  sideBySide1: {
    // backgroundColor: '#292929',

    padding: ['1rem'],
    '.imageContainer': {
      width: ['', '', '55%'],
      order: ['', '', '2'],
    },
    '.image': {
      height: ['', '', '', '75vh'],
    },
    '.content': {
      width: ['', '', '45%'],
      textAlign: 'left',
      alignItems: 'flex-start',
      padding: ['1rem', '2rem', '3rem', '', '4rem'],
      order: ['', '', '1'],
    },

    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },

    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },
  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.imageContainer': {
      width: ['', '', '55%'],
      order: ['', '', 'unset'],
    },
    '.image': {
      height: ['', '', '', '75vh'],
    },
  },

  // ? ==========================
  // ? ======  Homepage  ========
  // ? ==========================

  homepageHero: {
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      borderColor: 'white',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white',
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
    },
    '.hero_content_container': {
      margin: [
        '0rem 0rem 0rem 1rem',
        '0rem 0rem 0rem 2rem',
        '0rem 0rem 0rem 2rem',
        '0rem 0rem 0rem 4rem',
      ],
      backgroundColor: '#4c426fc9',
      border: '10px solid #b2b2b2',
    },
    '.slick-slider': {
      '.slick-arrow': {
        width: '40px',
        height: '40px',
        backgroundColor: 'secondary',
        padding: '0.5rem',
        color: 'white',

        transform: 'unset',
        ':hover': {
          opacity: '0.7',
        },
      },
      '.slick-prev': {},
      '.slick-next': {},
    },
  },

  homepageHeroShout: {},

  shoutSection: {
    padding: ['1rem'],
    // marginBottom: '3rem',
    '.imageContainer ': {
      // width: ['', '50%', '60%'],
      order: ['2', '', '1'],
      height: ['', '', '', '75vh'],
    },
    '.image': {
      borderRadius: ['0px', '', '0px 500px 500px 0px'],
      height: ['', '', '', '75vh'],
    },
    '.content': {
      color: 'white',
      textAlign: 'left',
      alignItems: 'flex-start',
      padding: ['1rem', '2rem', '3rem', '3rem'],
      order: ['1', '', '2'],
    },
    '.date, .title, .text': {
      textAlign: 'left',
      fontWeight: 'normal',
      margin: '0rem',
    },

    '.title': {
      display: 'flex',
      flexDirection: 'column',
      '::before': {
        content: "'Socialize'",
        borderBottom: '3px solid',
        borderColor: 'grey',
        paddingBottom: '1rem',
        fontSize: ['', '', '', '3rem'],
        textTransform: 'uppercase',
        marginBottom: '1rem',
        color: 'primary',
      },
      color: 'light',
      paddingBottom: '1rem',
      fontSize: ['', '', '', '1.75rem'],
      textTransform: 'uppercase',
      width: 'fit-content',
      margin: '0rem',
    },

    '.date': {
      opacity: '0.8',
      order: '5',
      fontSize: '1rem',
    },

    '.text': {
      fontSize: ['1rem', '1.25rem', '1.75rem'],
      lineHeight: ['1.5', '', '', '1.5'],
      color: 'textSecondary',
      marginBottom: '1rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '1px',
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
  },
  homepageMenu: {
    variant: 'customVariants.sideBySide2',
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2',
  },

  homepageContentSection: {
    color: 'white',
    padding: '20vh 1rem',
    '.section': {
      backgroundColor: '#4c426fc9',
      border: '10px solid #b2b2b2',
      padding: '1.5rem',
    },

    '.title': {
      variant: 'customVariants.title',
      order: '1',
      color: 'white',
      borderColor: 'white',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2',
    },
    '.text': {
      variant: 'customVariants.text',
      order: '3',
      color: 'white',
      p: {
        color: 'white',
      },
    },

    a: {
      order: '4',
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  // ? ==========================
  // ? =====  About Page  =======
  // ? ==========================

  aboutSection: {},

  // ? ==========================
  // ? ======  Menu Page  =======
  // ? ==========================

  menu: {
    '.dropdownContainer': {
      display: 'none',
    },

    '#menuTab': {
      fontFamily: 'heading',
      padding: '0.5rem 1rem 1rem',
      fontSize: '1.5rem',
      fontWeight: 'normal',
    },

    marginY: '2rem',

    '*': {
      boxShadow: 'none',
    },
    '.menuLogo': {
      marginBottom: '2rem',
    },
    '.menuSectionTitle': {
      color: 'primary',
      fontWeight: 'normal',
      borderBottom: 'solid 2px',
      borderColor: 'primary',
      paddingBottom: '0.5rem',
      fontSize: ['1.75rem', '2rem', '2.5rem'],
      textAlign: 'left',
      textTransform: 'uppercase',
      width: '95%',
      margin: ['0rem 0rem 1rem 0.5rem', '', '0rem 1.5rem 1.5rem'],
    },
    '.menuSectionDescription': {
      marginBottom: '1rem',
      textAlign: 'left',
      padding: ['1rem', '', '1rem 1.75rem'],
      color: 'primary',
    },
    '.menuItemContainer': {
      margin: '0rem',
      '.menuItemInnerContainer': {
        margin: '0rem',
      },
    },

    '.menuItemName': {
      color: 'secondary',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      margin: '0rem',
      fontFamily: 'heading',
    },
    '.menuItemContentContainer': {
      padding: '0rem',
      margin: '0rem',
    },
    '.menuItemPrice': {
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontFamily: 'heading',
    },
    '.menuItemDescription': {
      fontSize: '1rem',
      fontWeight: '600',
      opacity: '0.6',
    },

    '.itemVariantsContainer': {
      padding: '0.75rem 0rem 0.25rem',
    },

    '.menuItemPriceLabel': {
      fontSize: '0.9rem',
    },

    '.menuItemPriceVariants': {
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      // fontSize: ['1rem', '1.15rem', '1.25rem'],
      fontFamily: 'heading',
    },

    '.section-cell': {
      width: [
        '100%',
        'calc(50% - (0.5rem * 2))',
        'calc(33.333% - (0.5rem * 2))',
      ],
      borderRadius: '0px',
      backgroundColor: 'transparent',
      border: 'none',
      position: 'relative',
    },
    '.menuCell': {
      borderRadius: '0px',
    },

    '.cellImage': {
      borderRadius: '0px',
    },

    '.cellName': {
      position: 'absolute',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center',
      width: '100%',
      top: '50%',
      left: '50%',
      color: 'light',
      textTransform: 'uppercase',
      fontSize: ['1rem', '1.15rem', '1.75rem'],
      margin: '0rem',
      fontFamily: 'heading',
      padding: '0.5rem',
    },

    '#sec-XGEAqOWGTK2_2JzSNDoIDQ': {
      '.menuItemsContainer': {
        padding: '1rem',
      },
      '.menuItemContainerImgActive': {
        width: [
          'calc(50% - (0.5rem * 2))',
          'calc(33.3% - (0.5rem * 2))',
          'calc(25% - (0.5rem * 2))',
        ],
        padding: '0rem',
        '.menuItemInnerContainer': {
          margin: '0rem',
          '.menuItemName': {
            fontSize: '1rem',
          },
          '.itemVariantsContainer': {
            padding: '0rem',
          },
        },
      },
    },
  },

  // ? ==========================
  // ? =====  Gallery Page  =====
  // ? ==========================

  gallery: {},

  // ? ==========================
  // ? =====  Events Page  ======
  // ? ==========================

  events: {},

  // ? ==========================
  // ? =====  Contact Page  =====
  // ? ==========================

  locationsMap: {},

  hours: {
    '.title': {
      variant: 'customVariants.title',
    },
  },
  contactForm: {
    // backgroundColor: 'dark',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1632757600/sites/jrs-place/jr-branding-bg.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'black',
    '.title': {
      variant: 'customVariants.title',
      padding: '2rem',
      color: '#5b4f77',
      backgroundColor: 'white',
      border: 'solid 3px',
      borderColor: '#5b4f77',
      fontSize: '3rem',
    },
    '.contactDetails-container': {
      padding: '1rem',
      border: 'solid 3px',
      borderColor: '#5b4f77',
      backgroundColor: '#ffeaea',
      fontWeight: 'bold',
    },

    '.inputField, .textField ': {
      borderColor: '#5b4f77',
      borderRadius: '0px',
      backgroundColor: 'black',
      color: 'white',
      '::placeholder': {
        color: 'white',
      },
    },

    '.submitBtn': {
      backgroundColor: 'black',
      padding: '0.25rem 1rem 1rem',
    },
  },
}
