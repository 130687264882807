export default {
  text: '#202020',
  textSecondary: '#c17b50',
  primary: 'purple',
  secondary: '#4c426f',
  background: 'light',
  backgroundSecondary: '#6c9d2a',
  light: '#FFF',
  dark: '#272727',
}
