export default {
  heading: 'Battambang, serif',
  subheading: 'Kalam, cursive',
  body: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Kalam, cursive',
  googleFonts: [
    'Open Sans',
    'Battambang:100,300,400,700,900',
    'Kalam:300,400,700',
  ],
  // customFamilies: ['Burford-Rustic'],
  // customUrls: ['https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css'],
}
